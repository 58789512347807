<template>
  <SkeletonBaseBackground class="battlepass-card" v-bind="cardSize" :border-radius="12">
    <SkeletonBaseForeground
      :class="isOuter ? 'battlepass-card__image' : 'battlepass-card__image_inner'"
      :width="imageSize"
      :height="imageSize"
      :border-radius="600"
    />
    <div class="battlepass-card__texts">
      <SkeletonBaseForeground :width="changeXL(96, 80)" :height="textHeight" :border-radius="4" />
      <SkeletonBaseForeground :width="changeXL(152, 120)" :height="textHeight" :border-radius="4" />
    </div>
    <SkeletonBaseForeground
      v-if="showBottom"
      class="battlepass-card__bottom"
      :width="bottomSize"
      :height="bottomSize"
      :border-radius="600"
    />
  </SkeletonBaseBackground>
</template>

<script setup lang="ts">
import type { IBattlePassCardProps } from '~/features/skeleton/components/BattlepassCard/BattlePassCard.types';

const { changeByMedia } = GlobalUtils.Media;
const changeXL = changeByMedia('xl');

const cardSize = computed(() => ({
  height: changeXL(285, 188),
  width: changeXL(200, 144),
}));

withDefaults(defineProps<IBattlePassCardProps>(), {
  isOuter: true,
  showBottom: true,
});

const imageSize = computed(() => changeXL(144, 96));

const textHeight = computed(() => changeXL(20, 12));

const bottomSize = computed(() => changeXL(30, 24));
</script>

<style scoped lang="scss" src="./BattlepassCard.scss" />
