<template>
  <div class="daily-cases-profile">
    <div class="daily-cases-profile___avatar-container">
      <SharedBadge
        :text="userLevel"
        :text-color="Colors.NEUTRAL.WHITE"
        :badge-color="levelColor"
        :show-level="!!userInfo.userLevel"
        :size="changeXL('large', 'small')"
      >
        <div class="daily-cases-profile__avatar-background">
          <div class="daily-cases-profile__avatar-block">
            <NuxtImg
              v-if="userInfo.avatar"
              :src="userInfo.avatar"
              :class="['daily-cases-profile__avatar', avatarClasses]"
            />
          </div>
        </div>
      </SharedBadge>
    </div>
    <div class="daily-cases-profile__info">
      <p class="daily-cases-profile__nickname">{{ userInfo.nickname }}</p>
      <div class="daily-cases-profile__progress">
        <div v-if="!!userInfo.userLevel" class="daily-cases-profile__progress_bar"></div>
      </div>
      <p class="daily-cases-profile__xp">{{ xpuserInfo }}</p>
    </div>
    <SharedKitButton
      class="daily-cases-profile__button"
      :color-preset="buttonOptions.colorPreset"
      :size-preset="changeXL(SizePresets.L, SizePresets.S)"
      :type-preset="TypePresets.ROUND"
      :link-props="{
        to: buttonOptions.linkTo,
        external: true,
      }"
      :text="buttonOptions.text"
      @click.prevent="buttonOptions.action"
    />
  </div>
</template>

<script setup lang="ts">
import type { IDailyCasesProfileEmits, IDailyCasesProfileProps } from './DailyCasesProfile.types';
import { SizePresets, TypePresets } from '~/components/SharedKitButton/SharedKitButton.presets';
import { MainPageButtonPreset } from '~/features/mainPage/presets/SharedKitButton.presets';
import { Colors } from '~/constants/colors.constants';
import { useUserStore } from '~/store/user/user.store';
import { useLevelGeneration } from '~/features/cases/composables/useLevelGeneration';
import { DailyCaseEvents } from '~/repository/amplitude/events/dailyCases';
import { EDailyCasesButtonPlaces } from '~/repository/extensions/amplitude/amplitude.types';

const userStore = useUserStore();

const { t } = useI18n();

const emits = defineEmits<IDailyCasesProfileEmits>();
const props = defineProps<IDailyCasesProfileProps>();
const { userInfo } = toRefs(props);

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const { handlerGenerateLevelClick } = useLevelGeneration();

const onGenerateLevel = () => {
  handlerGenerateLevelClick();
  DailyCaseEvents.levelGenerated({ 'Button Place': EDailyCasesButtonPlaces.FREE_CASES_PAGE });
};

const xpProgressWidth = computed(() => {
  if (!userInfo.value.currentXp || !userInfo.value.nextLvlXp) return '0%';
  return (+userInfo.value.currentXp / (+userInfo.value.nextLvlXp + +userInfo.value.currentXp)) * 100 + '%';
});

const buttonOptions = computed(() => {
  if (!userStore.user?.wasGeneratedLvl) {
    return {
      colorPreset: MainPageButtonPreset.DAILY_CASES,
      linkTo: '',
      stat: { 'Button Place': 'banner', 'Button Type': 'generation_lvl' },
      text: t('mainPage.dailyCasesProfile.generateLvl'),
      action: onGenerateLevel,
    };
  }

  if (!userInfo.value?.availableCases?.length) {
    return {
      colorPreset: MainPageButtonPreset.DAILY_CASES,
      linkTo: useLinks().DAILY_CASES_XP_POPUP,
      stat: { 'Button Place': 'banner', 'Button Type': 'up_lvl' },
      text: t('mainPage.dailyCasesProfile.moreExp'),
      action: () => emits('onGetExperience'),
    };
  }

  return {
    colorPreset: MainPageButtonPreset.DAILY_CASES,
    linkTo: useLinks().FREE_CASES,
    stat: { 'Button Place': 'banner', 'Button Type': 'open_free_case' },
    text: t('mainPage.openFree'),
    action: () => emits('onOpenCases'),
  };
});

// Если у пользователя не сгенерирован уровень с бэка возвращается уровень -1, корректируем для вывода в виджете
const userLevel = computed(() => {
  return Math.max(Number(userInfo.value.userLevel), 0);
});

const xpuserInfo = computed(() => {
  // Если у пользователя не сгенерирован уровень с бэка возвращается currentXp: -1 и nextLvlXp: -1, корректируем для вывода в виджете
  const currentXp = Math.max(Number(userInfo.value?.currentXp), 0);
  const nextLvlXp = Math.max(Number(userInfo.value?.nextLvlXp), 0);

  return `${currentXp} / ${nextLvlXp + currentXp} XP`;
});

const avatarClasses = computed(() => ({ '--no-border': !userInfo.value.userLevel }));

const levelColor = computed(() => {
  return GlobalUtils.Colors.parseLvlColors(+(userInfo.value.userLevel ?? '0'));
});

// При уровнях 120 и выше - цвет становиться градиентом, для этого снизу после проверки на уровень
// конвертируем solid цвет в linear-gradient и ее биндим в переменную
const fixedLevelColors = computed(() => {
  if (+(userInfo.value.userLevel ?? 0) > 110) {
    return GlobalUtils.CSS.createLinearGradient({
      colorStops: levelColor.value as string[],
      toDirection: '62.8deg',
    }).backgroundImage;
  }
  return levelColor.value;
});
</script>

<style scoped lang="scss">
.daily-cases-profile {
  --level-color: v-bind('fixedLevelColors');
  --xp-progress-width: v-bind('xpProgressWidth');
}
</style>

<style scoped lang="scss" src="./DailyCasesProfile.scss"></style>
