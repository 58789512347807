<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="daily-cases-available-cases">
    <template v-if="userInfo.availableCases?.length && userStore.user?.wasGeneratedLvl">
      <div :class="['daily-cases-available-cases__container', casesContainerClasses]">
        <div
          v-for="(item, id) in userInfo.availableCases"
          :key="id"
          class="daily-cases-available-cases__image-container"
        >
          <NuxtImg
            v-if="getImagePath(item.id)"
            provider="localProvider"
            class="daily-cases-available-cases__image"
            :src="getImagePath(item.id)"
          />
        </div>
      </div>
      <div class="daily-cases-available-cases__subtext">
        {{ userInfo.availableCases?.length }} {{ availableCasesDeclination }}
      </div>
    </template>
    <template v-else>
      <div class="daily-cases-available-cases__block">
        <template v-if="userInfo.userLevel && userStore.user?.wasGeneratedLvl">
          <p v-if="+userInfo.userLevel > 0 && refinedTime">
            {{ $t('mainPage.dailyCasesAvailableCases.cooldown') }}
            <span class="daily-cases-available-cases__block_highlighted">
              {{ timerData.hours }}:{{ timerData.minutes }}:{{ timerData.seconds }}
            </span>
          </p>
          <I18nT keypath="mainPage.dailyCasesAvailableCases.moreExp" tag="p">
            <template #exp>
              <span class="daily-cases-available-cases__block_highlighted">{{ nextLvlCaseData.exp }}</span>
            </template>
            <template #link>
              <NuxtLinkLocale class="daily-cases-available-cases__block_link" :to="nextLvlCaseData.link">
                {{ nextLvlCaseData.text }}
              </NuxtLinkLocale>
            </template>
          </I18nT>
        </template>
        <template v-else>
          <p>{{ $t('mainPage.dailyCasesAvailableCases.noLevel1') }}</p>
          <p>{{ $t('mainPage.dailyCasesAvailableCases.noLevel2') }}</p>
        </template>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { IDailyCasesAvailableCasesProps } from './DailyCasesAvailableCases.types';
import { useUserStore } from '~/store/user/user.store';
import DCImage1 from 'assets/images/mainPage/dailyCases/lvl1.png';
import DCImage5 from 'assets/images/mainPage/dailyCases/5lvl.avif';
import DCImage10 from 'assets/images/mainPage/dailyCases/10lvl.avif';
import DCImage15 from 'assets/images/mainPage/dailyCases/15lvl.avif';
import DCImage20 from 'assets/images/mainPage/dailyCases/20lvl.avif';
import DCImage30 from 'assets/images/mainPage/dailyCases/30lvl.avif';
import DCImage40 from 'assets/images/mainPage/dailyCases/40lvl.avif';
import DCImage50 from 'assets/images/mainPage/dailyCases/50lvl.avif';
import DCImage60 from 'assets/images/mainPage/dailyCases/60lvl.avif';
import DCImage70 from 'assets/images/mainPage/dailyCases/70lvl.avif';
import DCImage80 from 'assets/images/mainPage/dailyCases/80lvl.avif';
import DCImage90 from 'assets/images/mainPage/dailyCases/90lvl.avif';
import DCImage100 from 'assets/images/mainPage/dailyCases/100lvl.avif';
import DCImage110 from 'assets/images/mainPage/dailyCases/110lvl.avif';
import DCImage120 from 'assets/images/mainPage/dailyCases/120lvl.avif';
import DCImage130 from 'assets/images/mainPage/dailyCases/130lvl.avif';
import DCImage140 from 'assets/images/mainPage/dailyCases/lvl140.png';
import DCImage160 from 'assets/images/mainPage/dailyCases/lvl160.png';
import DCImage180 from 'assets/images/mainPage/dailyCases/lvl180.png';
import DCImage200 from 'assets/images/mainPage/dailyCases/lvl200.png';
import { useDailyCasesStore } from '~/store/dailyCases/dailyCases.store';

const { t } = useI18n();

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const props = defineProps<IDailyCasesAvailableCasesProps>();
const { userInfo } = toRefs(props);
const userStore = useUserStore();
const { dailyCases } = storeToRefs(useDailyCasesStore());

const refinedTime = ref(0);
const { timerData, start } = useTimer(refinedTime);

onBeforeMount(() => {
  watch(
    userInfo,
    (value) => {
      refinedTime.value = value?.nearestCaseTime ?? 0;
      start();
    },
    { deep: true, immediate: true },
  );
});

const dailyCaseImages = [
  { id: 1, src: DCImage1 },
  { id: 5, src: DCImage5 },
  { id: 10, src: DCImage10 },
  { id: 15, src: DCImage15 },
  { id: 20, src: DCImage20 },
  { id: 30, src: DCImage30 },
  { id: 40, src: DCImage40 },
  { id: 50, src: DCImage50 },
  { id: 60, src: DCImage60 },
  { id: 70, src: DCImage70 },
  { id: 80, src: DCImage80 },
  { id: 90, src: DCImage90 },
  { id: 100, src: DCImage100 },
  { id: 110, src: DCImage110 },
  { id: 120, src: DCImage120 },
  { id: 130, src: DCImage130 },
  { id: 140, src: DCImage140 },
  { id: 160, src: DCImage160 },
  { id: 180, src: DCImage180 },
  { id: 200, src: DCImage200 },
];

const getImagePath = computed(() => (imageId: string) => {
  return dailyCaseImages.find((image) => image.id === +imageId)?.src;
});

const casesContainerClasses = computed(() => ({
  '--couple-items': (userInfo.value?.availableCases ?? []).length < changeXL(4, 5),
}));

const dailyCaseImageWidth = computed(() => {
  return GlobalUtils.CSS.proceedCssValue(changeXL(72, 48), 'px');
});

const availableCasesDeclination = computed(() => {
  const cases = t('freeCases.cases', { count: userInfo.value.availableCases?.length });
  const casesAvailable = t('freeCases.casesAvailable', { count: userInfo.value.availableCases?.length });
  return `${cases} ${casesAvailable}`;
});

const nextLvlCaseData = computed<Record<string, string>>(() => {
  const nextDailyCase = dailyCases.value.find((item) => Number(item?.level) >= Number(userInfo.value.userLevel));
  if (!nextDailyCase) return { text: '', link: '', exp: '' };

  const { level, link, exp } = nextDailyCase;

  return {
    text: t('mainPage.dailyCasesAvailableCases.nextLvlCase', { level }),
    link: `${useLinks().OPEN_CASE}${link}`,
    exp: `${exp}`,
  };
});
</script>

<style scoped lang="scss">
.daily-cases-available-cases {
  --daily-case-image-width: v-bind(dailyCaseImageWidth);
}
</style>

<style scoped lang="scss" src="./DailyCasesAvailableCases.scss"></style>
